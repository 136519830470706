<template>
  <div class="Home">
    <Navbar></Navbar>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "@/firebase/firebaseInit";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  name: "Home",
  components: {
    Navbar: Navbar,
    Footer: Footer,
  },
  methods: {
    loadFirebaseUser() {
      if (!firebase.auth().currentUser) {
        // this.isLoggedIn = false;
      } else {
        //get user id from firebase auth
        this.userId = firebase.auth().currentUser.uid;
        // this.isLoggedIn = true;

        //get user document from firestore
        var docRef = db.collection("users").doc(this.userId);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log("firebase GET userDoc SUCCESS");
              // save userDoc in store
              this.$store.commit("setUser", doc.data());
            } else {
              // doc.data() will be undefined in this case
              console.log("UserDoc not found!");
            }
          })
          .catch((error) => {
            console.log("firebase GET userDoc ERROR: ", error);
          });
      }
    },
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      },
    },
  },
  created() {
    this.loadFirebaseUser();

    //set dashboard mode to false
    this.$store.commit("setIsDash", false);
  },
};
</script>

<style scoped></style>
