<template>
  <div
    v-bind:class="{ 'navbar-dash': isDash }"
    class="navbar navbar-expand-lg navbar-light bg-light"
  >
    <a class="navbar-brand" href="/">
      <img
        src="@/assets/logos/talkii-logo-gradient.png"
        width="65"
        height="50"
        alt="Talkii Logo"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <!-- <li class="nav-item ml-4">
          <router-link :to="{ name: 'Plans' }" class="nav-link">
           Store
          </router-link>
        </li>
        <li v-if="isLoggedIn" class="nav-item ml-4">
          <router-link :to="{ name: 'Dashboard' }" class="nav-link">
            My Subscription
          </router-link>
        </li>
        <li v-if="isLoggedIn" class="nav-item ml-4">
          <router-link :to="{ name: 'Download' }" class="nav-link">
            Download
          </router-link>
        </li>
        <li v-if="isLoggedIn" class="nav-item ml-4">
          <router-link :to="{ name: 'SupportDashboard' }" class="nav-link">
            Support
          </router-link>
        </li> -->
        <!-- <li class="nav-item ml-4">
                  <a class="nav-link" href="/contact">Feedback</a>
              </li> -->

        <!-- user account dropdown -->
        <!-- <li v-if="isLoggedIn" class="nav-item ">
          <b-nav-item-dropdown
            class="ml-4"
            id="my-nav-dropdown"
            toggle-class="nav-link-custom"
            right
            no-caret
          >
            <template #button-content>
              <i class="fa fa-user-circle"></i>
            </template>
            <b-dropdown-group id="dropdown-header-1" header="Profile">
              <b-dropdown-text class="text-center">{{
                userDoc.name
              }}</b-dropdown-text>
              <b-dropdown-text class="text-center">{{
                userDoc.email
              }}</b-dropdown-text>
            </b-dropdown-group>
            <b-dropdown-group id="dropdown-header-2" header="Status">
              <b-dropdown-item class="text-center"
                ><span class="badge badge-pill badge-success">{{
                  userDoc.status
                }}</span></b-dropdown-item
              >
            </b-dropdown-group>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :to="{ name: 'Account' }">
              <span class="fal fa-user text-blue mr-3"></span> My Account
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="#"
              ><span class="fal fa-reply text-blue mr-3"></span>
              Logout</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </li> -->
        <li v-if="isLoggedIn" class="nav-item ml-4">
          <a class="nav-link" href="#" @click="logout"
            ><i class="fad fa-reply"></i> Logout</a
          >
        </li>
        <li v-if="!isLoggedIn" class="nav-item ml-4">
          <router-link :to="{ name: 'Login' }" class="nav-link">
            <i class="fad fa-user"></i> Login
          </router-link>
        </li>
        <li v-if="!isLoggedIn" class="nav-item ml-4">
          <a href="https://talkii.app" class="nav-link">
            <i class="fad fa-browser"></i> Back to site
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
// import C from "@/constants";

export default {
  data: () => ({
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    isLoggedIn: false,
    userId: null,
  }),
  methods: {
    logout() {
      console.log("logout");
      // if ((this.$store.getters.getMode != C.MODE.DEFAULT) && confirm('Etes-vous sûr? Tous les données seront perdues.') ) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("finishProcess");
          this.$router.replace("/login");
        });
      //}
    },
    init() {
      if (!firebase.auth().currentUser) {
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
      }
    },
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      },
    },
    isDash: {
      get() {
        return this.$store.getters.getIsDash;
      },
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped></style>
