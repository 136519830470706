<template>
  <div>
    <Navbar></Navbar>
    <div class="wrapper">
      <!-- section prices -->
      <section
        id="login"
        v-bind:class="{ 'bg-blue': !isAdmin, 'bg-orange': isAdmin }"
        class="section jpadding jpadding-20 section-unfixed bg-blue"
      >
        <!-- 
            <div class="illustration">
                <img src="/img/vectors/talkii-illustration2.svg" alt="">
            </div> -->

        <div class="jcard jcard-main jcard-login jcard-nohover">
          <div class="brand mb-3">
            <img
              src="@/assets/logos/talkii-logo-gradient.png"
              alt="Talkii Logo"
            />
          </div>
          <div class="sub-title text-center">
            <h2 v-if="!isAdmin">Login</h2>
            <h2 v-else>Admin</h2>
          </div>

          <form class="login">
            <div v-if="!loading">
              <div class="form-group">
                <label for="email"><i class="fa fa-user"></i> Email</label>
                <input type="text" class="form-control" v-model="email" />
              </div>

              <div class="form-group">
                <label for="password"
                  ><i class="fa fa-lock"></i> Password</label
                >
                <input
                  type="password"
                  class="form-control"
                  v-model="password"
                />
              </div>

              <div v-if="!isAdmin" class="btn-container text-center mt-4 mb-4">
                <button type="submit" class="jbtn jbtn-blue" @click="login">Let's go!</button>
              </div>

              <div v-else class="btn-container text-center mt-4 mb-4">
                <button class="jbtn jbtn-blue" @click="loginAdmin">
                  Admin Login
                </button>
              </div>
            </div>
            <div v-if="loading" class="text-center loading-container">
              <i class="text-blue fad fa-spinner-third fa-spin fa-3x"></i>
            </div>

            <b-alert v-model="showAlert" class="login-message" variant="danger"
              ><i class="fal fa-exclamation-triangle"></i>
              {{ loginMsg }}</b-alert
            >

            <div class="bottom-links text-center">
              <button type="button" class="jbtn-text" @click="showForgotPasswordDialog">
                Forgot password?
              </button>
              <!-- <router-link :to="{ name: 'Plans' }"
                ><p>Create an account</p></router-link
              > -->
            </div>
          </form>
        </div>
      </section>
      <!-- ----- change email modal --------------------------------- -->
      <b-modal
        hide-header="true"
        hide-footer="true"
        id="forgot-password-modal"
        ref="forgot-password-modal"
        centered
        title="BootstrapVue"
      >
        <div class="jdialog-top">
          <div class="close-container">
            <h3><strong>Reset Password</strong></h3>
            <span
              @click="$bvModal.hide('forgot-password-modal')"
              class="ml-auto jclose"
              ><i class="fa fa-times "></i
            ></span>
          </div>
          <p>
            <small
              >You will receive an email, which will allow you to reset your
              password. Please enter the email address associated with your Talkii account.</small
            >
          </p>
        </div>
        <div class="jdialog-main">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="name">
                  <i class="fal fa-lock"></i> Your email
                </label>
                <input
                  type="email"
                  v-bind:class="{
                    'form-control': true,
                    'is-invalid':
                      !validEmail(emailForReset) && bluredEmailForReset,
                  }"
                  v-on:blur="bluredEmailForReset = true"
                  v-model="emailForReset"
                />
                <div class="invalid-feedback">
                  Please enter your current email.
                </div>
              </div>
            </div>
          </div>

          <b-alert v-if="showInputError" show variant="danger"
            ><i class="fad fa-exclamation-circle"></i>
            {{ validationMsg }}
          </b-alert>
        </div>
        <div class="jdialog-bottom with-cancel">
          <button
            @click="$bvModal.hide('forgot-password-modal')"
            class="jbtn jbtn-sm jbtn-red"
          >
            <i class="fa fa-times"></i> Cancel
          </button>
          <button @click="sendPasswordResetEmail" class="jbtn jbtn-sm">
            <span v-if="isSaving" key="spinner"
              ><i class="fad fa-spinner-third fa-spin"></i
            ></span>
            <span v-else key="button"><i class="fa fa-envelope"></i> Send</span>
          </button>
        </div>
      </b-modal>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "@/firebase/firebaseInit";
import Navbar from "@/components/Navbar";
import toast from "@/assets/js/toast";

export default {
  name: "Login",
  components: {
    Navbar: Navbar,
  },
  data() {
    return {
      loginTitle: "Bonjour",
      loading: false,
      showAlert: false,
      email: "",
      password: "",
      loginMsg: "",
      emailForReset: "",
      bluredEmailForReset: false,

      currentUser: firebase.auth().currentUser,

      isAdmin: false,
    };
  },
  methods: {
    showForgotPasswordDialog() {
      this.$refs["forgot-password-modal"].show();
    },
    initUpdateEmail() {
      this.isSaving = true;
      if (!this.validatePasswordResetEmail()) {
        this.isSaving = false;
        return;
      }

      this.sendPasswordResetEmail();
    },
    sendPasswordResetEmail() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.emailForReset)
        .then(() => {
          this.isSaving = false;
          //hide modal
          this.$refs["forgot-password-modal"].hide();
          //show toast
          toast.success("Password reset email sent. Check your inbox.");
        })
        .catch((error) => {
          // var errorCode = error.code;
          // var errorMessage = error.message;
          toast.error("Could't reset password. " + error.message);
          this.isSaving = false;
        });
    },
    login() {
      this.loading = true;
      this.showAlert = false;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$store.commit(
            "setUsername",
            firebase.auth().currentUser.displayName
          );
          this.$store.commit("login");
          this.loginMsg = "SUCCESS";
          this.$router.replace("/");
        })
        .catch((err) => {
          this.loginMsg = err.message;
          this.showAlert = true;
          this.loading = false;
        });
    },
    loginAdmin() {
      this.loading = true;
      this.showAlert = false;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$store.commit(
            "setUsername",
            firebase.auth().currentUser.displayName
          );
          //load user doc to check if admin
          var docRef = db
            .collection("users")
            .doc(firebase.auth().currentUser.uid);
          docRef.onSnapshot((doc) => {
            var isAdmin = doc.data().admin;
            console.log("User is Admin: ", isAdmin);
            this.$store.commit("setUser", doc.data());

            this.$store.commit("loginAdmin");
            this.loginMsg = "SUCCESS";
            this.$router.push({ name: "AdminDashboard" });
          });
        })
        .catch((err) => {
          this.loginMsg = err.message;
          this.showAlert = true;
          this.loading = false;
        });
    },
    setIsAdmin() {
      if (
        this.$route.params.type != null &&
        this.$route.params.type === "admin"
      ) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    },
    validatePasswordResetEmail() {
      this.bluredEmailForReset = true;
      this.emailForReset = this.emailForReset.trim().toLowerCase();
      if (!this.validEmail(this.emailForReset)) {
        return false;
      }

      return true;
    },
    validEmail(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
  },
  beforeRouteUpdate(to, from, next) {
    // just use `this`
    if (to.params.type != null && to.params.type === "admin") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    next();
  },
  created() {
    this.setIsAdmin();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
