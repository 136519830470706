import Vue from "vue";
import firebase from "firebase/app";
import VueRouter from "vue-router";
import store from '@/store/store.js';
//views:
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
// import C from "@/constants"

Vue.use(VueRouter);

const routes = [
  {
    name: "Login",
    path: "/login/:type?",
    component: Login,
  },
  {
    name: "User",
    path: "/",
    component: () => import("@/views/HomeLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "Dashboard",
        path: "",
        component: () => import("@/components/Dashboard"),
      },
      {
        name: "Account",
        path: "account",
        component: () => import("@/components/user/Account"),
      },
      {
        name: "Subscription",
        path: "subscription",
        component: () => import("@/components/cards/Subscription"),
      },
      {
        name: "NoSubscription",
        path: "no-subscription",
        component: () => import("@/components/cards/NoSubscription"),
      },
      {
        name: "Password",
        path: "password",
        component: () => import("@/components/user/Security"),
      },
      {
        name: "Download",
        path: "download",
        component: () => import("@/components/user/Download"),
      },
      {
        name: "Android",
        path: "android",
        component: () => import("@/components/user/Download"),
      },
      {
        name: "UserGuide",
        path: "userguide",
        component: () => import("@/components/user/UserGuide"),
      },
    ],
  },
  {
    name: "Support",
    path: "/support",
    component: Home,
    meta: {
      requiresAuth: true,
    },

    children: [
      {
        name: "SupportDashboard",
        path: "",
        component: () => import("@/components/support/SupportDashboard"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// make sure only logged-in users have access to the content while onyl guests(+not signed-in users) see register & login
router.beforeEach((to, from, next) => {
  console.log("NEXT: ");
  console.log(to);
  console.log("FROM: ");
  console.log(from);
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAdmin = store.getters.isAdmin;
  const requiresAdminAuth = to.matched.some((record) => record.meta.requiresAdminAuth);
  console.log("User is Admin: ", isAdmin);


  // if (to.fullPath === "/") {
  //   next("/");
  //   return;
  // } else if (requiresAuth && !currentUser) next(Login);
  // else if (requiresAdminAuth && !isAdmin) next(Login);
  if (requiresAuth && !currentUser) next(Login);
  else if (requiresAdminAuth && !isAdmin) next(Login);
  //else if (!requiresAuth && currentUser) next("user");
  else next();
});

export default router;
